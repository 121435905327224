import Moment from "moment";
Moment.locale("zh-CN");

export const time2YearMont = (time) => {
  return Moment(time).format("YYYY-MM");
};

export const time2YMText = (time) => {
  return Moment(time).format("YYYY年MM月");
};

export const time2date = (time) => {
  return Moment(time).format("YYYY-MM-DD");
};

export const time2datetime = (time) => {
  return Moment(time).format("YYYY-MM-DD HH:mm:ss");
};

export const time2Houertime = (time) => {
  return Moment(time).format("HH:mm:ss");
};

const today = Moment();
const yesterday = Moment().subtract(1, "days");
const monday = Moment().week(Moment().week()).startOf("week");
const sunday = Moment().week(Moment().week()).endOf("week");
const lastMonday = Moment()
  .week(Moment().week() - 1)
  .startOf("week");
const lastSunday = Moment()
  .week(Moment().week() - 1)
  .endOf("week");
const beginOfThisMonth = Moment().startOf("month");
const beginOfLastMonth = Moment()
  .month(Moment().month() - 1)
  .startOf("month");
const endOfLastMonth = Moment()
  .month(Moment().month() - 1)
  .endOf("month");
export const dateRanges = {
  今天: [today, today],
  昨天: [yesterday, yesterday],
  本周: [monday, sunday],
  上周: [lastMonday, lastSunday],
  本月: [beginOfThisMonth, today],
  上月: [beginOfLastMonth, endOfLastMonth],
};

export const formatNumber = (value) => {
  if (!value) {
    return "0.00";
  }
  return new Number(value / 100).toFixed(2);
};

export const fuzzMobile = (phonenumber) => {
  if (!phonenumber) {
    return "-";
  }
  const stringNumber = String(phonenumber);
  if (stringNumber.length == 11) {
    return stringNumber.replace(/^(\d{3})[\d|*]{6}(\d{2})$/, "$1******$2");
  }
  return stringNumber;
};

export const fuzzBankCardNum = (cardnumber) => {
  const num = String(cardnumber);
  return "******" + num.substr(num.length - 4);
};

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
}

/**
 * 得到浏览器信息
 */
export const getBrowserInfo = function () {
  let u = navigator.userAgent;
  return {
    //终端浏览器版本信息
    language: (navigator.browserLanguage || navigator.language).toLowerCase(), //由浏览得到正在使用的语言
    ie: u.indexOf("Trident") > -1, //IE内核
    presto: u.indexOf("Presto") > -1, //opera内核
    webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
    iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, //是否iPad
    webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
    wechat: !!u.match(/MicroMessenger/i),
    qq: !!u.match(/AppleWebKit.*Mobile.*/) && !!u.match(/QQ/i),
    weibo: !!u.match(/AppleWebKit.*Mobile.*/) && !!u.match(/WeiBo/i),
  };
};

/**
 * 睡眠模式
 */
export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

/**
 * 清除token
 */
export const clearToken = () => {
  localStorage.removeItem("currentUser");
};

/**
 * 秒转化时分秒
 */
export const formatSeconds = (value) => {
  let s = parseInt(value),
    m = 0,
    h = 0;

  if (s > 60) {
    m = parseInt(s / 60);
    s = parseInt(s % 60);
    if (m >= 60) {
      h = parseInt(m / 60);
      m = parseInt(m % 60);
    }
  }

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  return `${h}:${m}:${s}`;
};

/**
 * 字符串时分秒转化秒
 * time "00:00:00"
 */
export const time2Second = (time) => {
  let newArrTime = time.split(":");
  return newArrTime.reduce((n, e, i) => {
    switch (i) {
      case 0:
        n += +e * 60 * 60;
        break;
      case 1:
        n += +e * 60;
        break;
      case 2:
        n += +e;
        break;
      default:
        break;
    }
    return n;
  }, 0);
};

/**
 * 手机号
 */
export const isMobole = (n) => {
  return !/^1(\d){10}$/.test(n);
};
