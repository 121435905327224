<template>
  <router-view />
</template>
<style>
#app {
  width: 750px;
  min-height: 100vh;
  background: #fff;
  margin: 0 auto;
}
</style>
