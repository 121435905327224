import axios from "../axios";

export function changeNickname(data) {
  return axios({
    url: "/portal/user/changeNickname",
    method: "POST",
    data,
  });
}
export function getMyRoomList(data) {
  return axios({
    url: "/portal/user/getMyRoomList",
    method: "POST",
    data,
  });
}
export function getMySceneList(data) {
  return axios({
    url: "/portal/user/getMySceneList",
    method: "POST",
    data,
  });
}
export function bindPhone(data) {
  return axios({
    url: "/portal/user/changePhone",
    method: "POST",
    data,
  });
}
export function changeTransactionPassword(data) {
  return axios({
    url: "/portal/user/changeTransactionPassword",
    method: "POST",
    data,
  });
}
export function changeLoginPassword(data) {
  return axios({
    url: "/portal/user/changeLoginPassword",
    method: "POST",
    data,
  });
}
export function bindEmail(data) {
  return axios({
    url: "/portal/user/changeEmail",
    method: "POST",
    data,
  });
}
export function changeSignature(data) {
  return axios({
    url: "/portal/user/changeSignature",
    method: "POST",
    data,
  });
}
export function planOrderList(data) {
  return axios({
    url: "/portal/planOrder/list",
    method: "POST",
    data,
  });
}
export function authLogout(data) {
  return axios({
    url: "/auth/logout",
    method: "POST",
    data,
  });
}
export function sendMailCode(data) {
  return axios({
    url: "/common/sendMailCode",
    method: "POST",
    data,
  });
}
export function getRoomAccessToken(data) {
  return axios({
    url: "/portal/user/getRoomAccessToken",
    method: "POST",
    data,
  });
}
