<template>
  <div class="choose_box">
    <van-cell title=" ">
      <template #right-icon>
        <i class="iconfont icon_close icon-close1" @click="close"></i>
      </template>
    </van-cell>
    <van-tabs v-model:active="active" @click-tab="onClickTab">
      <van-tab v-for="(item, index) in items" :key="item.id" :title="item.name" :name="index">
        <div class="tab_detail">
          <div class="tag_item">{{ item.description }}</div>
          <div class="skus_box">
            <div
              class="skus_main"
              :class="{ skus_active: current == childActive }"
              v-for="(child, current) in item.skus"
              @click="childActive = current"
              :key="child.id"
            >
              <div class="detail_box">
                <div class="name_item">{{ child.name }}</div>
                <div style="margin-top: 8px">
                  <span class="price_tag">￥</span><span class="price_item">{{ child.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="bottom_button" v-if="items.length !== 0">
      实付： <span class="price_tag">￥</span><span class="price_item">{{ items[active].skus[childActive].price }}</span>
      <van-button class="buy_item" size="small" @click="payClick">立即购买</van-button>
    </div>

    <van-dialog v-model:show="show" title="扫码支付">
      <div class="dialog_item">
        <qrcode-vue v-if="qrcode.url" class="code_main" :value="qrcode.url" :size="qrcode.size" level="H" />
        <div class="pay_type_box">
          <i class="colorfont icon color-zhifubao"></i><span style="margin-left: 4px">支付宝</span>
          <!-- <i class="colorfont icon color-weixin" style="margin-left:27px"></i><span style="margin-left:4px">微信</span> -->
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script setup>
const emit = defineEmits(["update:show"]);
import { onMounted, onUnmounted, reactive, ref } from "vue";
import { planList, planUnifiedOrder, checkPaymentStatus } from "@/api/w-axios/modules/home.js";
import QrcodeVue from "qrcode.vue";
import { Toast } from "vant";
import { jupUrl, jupUrl3 } from "@/utils/utils";
const props = defineProps(["list"]);
const { list } = props;
console.log(list);
const active = ref(0);
const childActive = ref(0);
const items = ref([]);
onMounted(() => {
  getPlanList();
});
async function getPlanList() {
  const { result } = await planList();
  items.value = result.items;
  clearTimeout(timer);
  timer = null;
  timer = setTimeout(() => {
    getPaymentStatus();
  }, 1500);
}
const onClickTab = () => {
  childActive.value = 0;
};
const show = ref(false);
const qrcode = reactive({
  url: "",
  trade_no: "",
  size: 200,
});
async function payClick() {
  const { result } = await planUnifiedOrder({
    scene_id: list.id,
    plan_sku_id: items.value[active.value].skus[childActive.value].id,
  });
  location.href = url;
  // jupUrl3(result.url);
}

let timer = null;
async function getPaymentStatus() {
  clearTimeout(timer);
  timer = null;
  if (!qrcode.trade_no) {
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      getPaymentStatus();
    }, 1500);
    return;
  }
  const { result } = await checkPaymentStatus({
    trade_no: qrcode.trade_no,
  });
  if (result.continue === 1) {
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      getPaymentStatus();
    }, 1500);
  }
  if (result.status === 1) {
    Toast("支付成功"), jupUrl("UserSpace");
  }
}
function close() {
  emit("update:show", false);
}
onUnmounted(() => {
  clearTimeout(timer);
});
</script>
<style lang="scss" scoped>
.choose_box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  .icon_close {
    font-size: 36px;
  }
}
.tab_detail {
  padding: 20px;
  .tag_item {
    border: 1px dotted #e8e8e8;
    border-radius: 5px;
    padding: 30px;
    color: #ce8200;
    font-size: 24px;
    line-height: 60px;
  }
  .skus_box {
    display: grid;
    grid-column: span 3;
    grid-template-columns: repeat(3, 1fr);
    box-sizing: border-box;
    grid-gap: 10px 10px;
    margin-top: 30px;
    .skus_main {
      background-color: #f7f9fc;
      text-align: center;
      padding: 20px 0;
      border-radius: 5px;
    }
    .skus_active {
      background-color: #fee7bf;
    }
    .detail_box {
    }
  }
}
.name_item {
  font-size: 26px;
  font-weight: bold;
}
.price_tag {
  color: #ff6600;
}
.price_item {
  color: #ff6600;
  font-size: 50px;
}
.bottom_button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: #ffffff;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 20px;
  .buy_item {
    position: absolute;
    right: 20px;
    background-color: #f9d790;
    border-radius: 28px;
    border: none;
    padding: 0 40px;
    font-size: 30px;
    font-weight: bold;
  }
}
.dialog_item {
  text-align: center;
}

.pay_type_box {
  margin-top: 16px;
  color: #989898;
  font-size: 12px;

  .icon {
    font-size: 18px;
    vertical-align: -2px;
  }
}
</style>
