<!--
 * @Author: 大年
 * @Date: 2022-09-05 14:22:52
 * @Description: 
-->
<template>
  <div class="com_footer">
    <div class="pos_content">
      <div class="gsyj">
        <div class="web_container">
          <div class="logo_text_box">
            <img src="@/assets/images/logo_item.png" alt="" />
            <div class="text_box">
              数字地名元宇宙. 2023 Web 3.0 时代先驱企业 立足元宇宙，展望新未来<br />
              为了更仔细地看月亮<br />
              多数人满足于透过望远镜所见之象 但我们，想要登上月球 伟大的成就从伟大的目标开始
            </div>
          </div>
          <!-- <div class="kf_wx">
            <div class="items">
              <img src="@/assets/images/service1.png" alt="" />
              <span>客服热线1</span>
            </div>
            <div class="items mg_lf_40">
              <img src="@/assets/images/service2.png" alt="" />
              <span>客服热线2</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="top_content">
        <div class="web_container">
          <div class="top_c">
            <div class="footer_logo">
              <img src="@/assets/images/logo-dark.svg" alt="" />
            </div>
            <div class="support_platform">
              <p class="sub_title">平台支持</p>
              <div class="list">
                <p class="item">
                  <img src="@/assets/images/mobile.png" alt="" />
                  <span>手机</span>
                </p>
                <p class="item mg30">
                  <img src="@/assets/images/pc.png" alt="" />
                  <span>电脑</span>
                </p>
                <p class="item">
                  <img src="@/assets/images/vr.png" alt="" />
                  <span>VR</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot_content">
        <div class="web_container">
          <span>Copyright © 2022 数字地名元宇宙 </span>
          <a href="https://beian.miit.gov.cn/" target="_blank">备案号：琼ICP备11002133号-15 </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComFooter",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.com_footer {
  position: relative;
  margin-top: 75px;
  .pos_content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    .gsyj {
      width: 100%;
      padding: 80px 0 80px 0;
      background: #e8e8e8;
      .web_container {
        padding: 30px;
        .logo_text_box {
          display: flex;
          align-items: center;
          padding: 30px 0 0 0;
          img {
            width: 95px;
            height: 131px;
          }
          .text_box {
            margin-left: 50px;
            line-height: 46px;
          }
        }
        .kf_wx {
          display: flex;
          text-align: center;
          .items {
            img {
              width: 128px;
              height: 128px;
              border-radius: 4px;
            }
            span {
              display: inline-block;
              font-size: 14px;
              color: #000000;
              margin-top: 10px;
            }
          }
          .mg_lf_40 {
            margin-left: 20px;
          }
        }
      }
    }
    .top_content {
      padding-bottom: 55px;
      border-bottom: 1px solid #ececec;
      .web_container {
        .top_c {
          display: flex;
          justify-content: space-between;
          padding: 40px 140px 0 46px;
          .footer_logo {
            width: 206px;
            height: 52px;
          }
          .support_platform {
            .sub_title {
              font-size: 18px;
              color: #0a0330;
            }
            .list {
              display: flex;
              margin-top: 10px;
              .item {
                text-align: center;
                img {
                  width: 74px;
                  height: 74px;
                  margin-bottom: 4px;
                }
                span {
                  font-size: 22px;
                  color: #0a0330;
                }
              }
              .mg30 {
                margin: 0 30px;
              }
            }
          }
        }

        .right_content {
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          .about_ours {
            margin-top: 110px;
            .sub_title {
              font-size: 36px;
              color: #0a0330;
            }
            .list {
              margin-top: 30px;
              p {
                font-size: 28px;
                color: #0a0330;
                line-height: 64px;
              }
            }
          }
        }
        .code_wrap {
          .items {
            text-align: center;
            img {
              width: 204px;
              height: 204px;
              margin: 100px auto 8px;
            }
            span {
              font-size: 28px;
              color: #0a0330;
            }
          }
        }
      }
    }
    .bot_content {
      padding: 40px 0;
      font-size: 24px;
      color: #0a0330;
      text-align: center;
      .web_container {
        padding: 0 40px;
        a {
          display: block;
          margin-top: 20px;
          color: #0a0330;
        }
      }
    }
  }
}
</style>
