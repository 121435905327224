<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:07:05
 * @Description: 联系我们
-->
<template>
  <div class="contact_us offsetTop">
    <div class="web_container">
      <div class="title">
        <p>联系我们</p>
      </div>
      <div class="contact_content">
        <div class="left_c">
          <div class="sub_title">联系方式</div>
          <div class="com_c_list">
            <img src="@/assets/images/tel.png" alt="" />
            <span>电话：16689698669</span>
          </div>
          <div class="com_c_list">
            <img src="@/assets/images/address.png" alt="" />
            <span>地址：海南省海口市龙华区广场路2-16号海南地名数字媒体运营中心</span>
          </div>
          <div class="com_c_list">
            <img src="@/assets/images/wx.png" alt="" />
            <span>微信客服热线</span>
          </div>
          <div class="wx_icon">
            <div class="items">
              <img src="@/assets/images/service1.png" alt="" />
              <span>客服热线1</span>
            </div>
            <div class="items mg_lf_40">
              <img src="@/assets/images/service2.png" alt="" />
              <span>客服热线2</span>
            </div>
          </div>
        </div>
        <div class="right_c">
          <div class="sub_title">在线留言</div>
          <div class="com_r_list">
            <div class="r_title">姓名</div>
            <div class="r_input">
              <van-field class="set_i_style" v-model="name" placeholder="姓名..." />
            </div>
          </div>
          <div class="com_r_list">
            <div class="r_title">手机号</div>
            <div class="r_input">
              <van-field class="set_i_style" v-model="phone" placeholder="手机号..." />
            </div>
          </div>
          <div class="com_r_list">
            <div class="r_title">微信号</div>
            <div class="r_input">
              <van-field class="set_i_style" v-model="weixin" placeholder="微信号..." />
            </div>
          </div>
          <div class="com_r_list">
            <div class="r_title">留言信息</div>
            <div class="textarea">
              <van-field v-model="content" rows="4" autosize type="textarea" placeholder="留言..." />
            </div>
          </div>
          <div @click="submit" class="submit_info">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, toRaw } from "vue";
import { messageSubmit } from "@/api/home.service";
import { Toast } from "vant";
import { isMobole } from "@/utils/util";
export default {
  name: "ContactModal",
  setup() {
    const state = reactive({
      name: "",
      phone: "",
      weixin: "",
      content: "",
    });

    const submit = async () => {
      if (!state.name) {
        Toast.fail("请填写姓名");
        return;
      }
      if (!state.phone) {
        Toast.fail("请填写手机号");
        return;
      }
      if (isMobole(state.phone)) {
        Toast.fail("请填写正确的手机号");
        return;
      }

      if (!state.content) {
        Toast.fail("请填写留言");
        return;
      }

      try {
        await messageSubmit(toRaw(state));
        Toast.success("提交成功,请耐心等待工作人员联系您！");
        Object.assign(state, {
          name: "",
          phone: "",
          weixin: "",
          content: "",
        });
      } catch (error) {
        let {
          data: { msg },
        } = error;
        Toast.fail(msg ? msg : "脚本错误");
      }
    };

    return {
      ...toRefs(state),
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
.contact_us {
  padding-top: 80px;
  overflow: hidden;
  .web_container {
    padding: 0 30px 0 40px;
    .title {
      p {
        display: inline-block;
        height: 100%;
        font-size: 48px;
        font-weight: bold;
        color: #0a0330;
        padding-bottom: 26px;
        border-bottom: 6px solid #fd3e5b;
      }
    }
    .contact_content {
      margin-top: 90px;
      .sub_title {
        font-size: 36px;
        font-weight: 500;
        color: #4a4a4a;
      }
      .left_c {
        span {
          font-size: 30px;
          color: #4a4a4a;
        }
        .com_c_list {
          display: flex;
          align-items: center;
          margin-top: 56px;
          img {
            width: 48px;
            height: 48px;
            margin-right: 4px;
          }
        }
        .wx_icon {
          display: flex;
          text-align: center;
          margin-top: 40px;
          .items {
            img {
              width: 260px;
              height: 260px;
              padding: 10px;
              // margin-bottom: 26px;
              border-radius: 8px;
              border: 1px solid #ddd;
            }
          }
          .mg_lf_40 {
            margin-left: 40px;
          }
        }
      }
      .right_c {
        margin-top: 70px;
        .com_r_list {
          margin-top: 30px;
          .r_title {
            font-size: 36px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 20px;
          }
          .r_input {
            width: 100%;
            background: #ffffff;
            border-radius: 20px 20px 20px 20px;
            border: 2px solid #ececec;
            overflow: hidden;
            .set_i_style {
              height: 100%;
            }
          }
          .textarea {
            border: 2px solid #ececec;
            border-radius: 20px 20px 20px 20px;
            overflow: hidden;
          }
        }
      }
      .submit_info {
        width: 100%;
        line-height: 120px;
        border: 2px solid #ececec;
        text-align: center;
        font-size: 36px;
        color: #ffffff;
        margin-top: 70px;
        background: linear-gradient(90deg, #15d1ea 0%, #0c1ed4 100%);
        border-radius: 30px;
      }
    }
  }
}
</style>
