<!--
 * @Author: 大年
 * @Date: 2022-09-05 14:22:52
 * @Description: 
-->
<template>
  <div>
    <div class="com_header" :class="getFixed()">
      <div class="web_container flexsb">
        <div class="left_logo">
          <img src="@/assets/images/logo-white.svg" alt="" />
        </div>
        <div @click="show = true" class="right_fn_list">
          <img src="@/assets/images/menu.png" alt="" />
        </div>
      </div>
      <van-popup v-model:show="show" position="right">
        <div class="p_menu_content">
          <ul>
            <li @click="selectNav(item, index)" :class="[current == index ? 'active' : '']" v-for="(item, index) in list" :key="index">
              {{ item.name }}
            </li>
          </ul>
          <ul class="user_item" v-if="user">
            <li class="avarat" @click="jupUrl('User')">
              <img :src="user.avatar ? user.avatar : require('@/assets/images/logo_item.png')" alt="" />
            </li>
            <li @click="jupUrl('UserSpaces')">我的空间</li>
            <!-- <li>
              账户信息
            </li> -->
          </ul>
          <ul class="user_item" v-else>
            <li @click="loginClick">登录</li>
          </ul>
        </div>
      </van-popup>
    </div>
    <LoginPanel v-if="loginShow" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch, computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { jupUrl } from "@/utils/utils.js";
import LoginPanel from "./LoginPanel.vue";
export default {
  name: "ComHeader",
  components: {
    LoginPanel,
  },
  props: {
    p_current: {
      type: Number,
      requrie: false,
      default: () => {
        return 0;
      },
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      list: [
        {
          name: "首页",
          routeName: "Home",
        },
        {
          name: "空间展示",
          routeName: "Spaces",
        },
        // {
        //   name: "合作报价",
        //   routeName: "ServicePrice",
        // },
        {
          name: "联系我们",
          routeName: "Contact",
        },
        // {
        //   name: "文档",
        //   href: "https://github.com/XVR-Art/Docs",
        //   isJump: true,
        // },
      ],
      current: 0,
      is_fixed: false,
      show: false,
    });

    const selectNav = (item, index) => {
      state.show = false;
      // if (item.isJump) {
      //   window.open(item.href, "_blank");
      //   return;
      // }
      router.push({
        name: item.routeName,
      });
    };

    const getFixed = () => {
      if (state.is_fixed || route.name != "Home") {
        return "fixed";
      }
      return "";
    };

    const handleScroll = () => {
      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      state.is_fixed = scroll > 80 ? true : false;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    const store = useStore();
    const user = computed(() => {
      return store.getters.user;
    });

    watch(
      () => props.p_current,
      (n) => {
        state.current = n;
      }
    );

    function loginClick() {
      console.log(66);
      store.dispatch("user/changeLoginTab", true);
    }

    const loginShow = computed(() => {
      return store.getters.loginTab;
    });
    return {
      ...toRefs(state),
      selectNav,
      getFixed,
      user,
      loginShow,
      jupUrl,
      loginClick,
    };
  },
};
</script>

<style scoped lang="scss">
.user_item {
  position: absolute;
  bottom: 150px;
  .avarat {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}
.com_header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  transition: all 0.8s;
  .web_container {
    height: 100%;
    padding: 20px;
    .left_logo {
      width: 336px;
      height: 104px;
    }
    .right_fn_list {
      width: 48px;
      height: 48px;
    }
  }
}
.fixed {
  position: fixed;
  left: 0;
  top: 0;
  background: #0a0d35;
}
.p_menu_content {
  height: 100vh;
  padding: 50px 200px 0 50px;
  background: #0a0d35;
  ul {
    li {
      font-size: 36px;
      color: #beb4b4;
      margin-bottom: 100px;
    }
    .active {
      font-weight: bold;
      color: #ffffff;
    }
  }
  .enter_yyz {
    width: 440px;
    line-height: 92px;
    background: #1d2eda;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
    font-size: 32px;
    color: #ffffff;
    margin: 150px auto 0;
  }
}
</style>
