import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/index.scss";
import "animate.css/animate.compat.css";
import "amfe-flexible";
import "amfe-flexible/index.js";

import "@/style/colorfont.css";
import "@/style/iconfont.css";

const app = createApp(App);
import { loadVant } from "@/utils/loadVant";
loadVant(app);

app.use(store).use(router).mount("#app");
