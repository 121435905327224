<!--
 * @Author: 大年
 * @Date: 2022-09-09 17:09:40
 * @Description: 
-->
<template>
  <div>
    <ul class="flexsb">
      <li @click="testClick(item)" v-for="(item, index) in list" :key="index">
        <div class="picker">
          <img :src="item.screenshot" alt="" />
          <!-- <div class="create_button" @click.stop="createClick(item)">创建空间</div> -->
        </div>
        <div class="name">{{ item.name }}</div>
      </li>
    </ul>
  </div>

  <SpaceDetail v-model:show="payShow" :list="chooseList" v-if="payShow" />
</template>

<script setup>
import { reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import SpaceDetail from "./SpaceDetail.vue";
import { jupUrl3 } from "@/utils/utils.js";
import { getRoomAccessToken } from "@/api/w-axios/modules/user.js";
import { Toast } from "vant";
const porps = defineProps(["list"]);
const store = useStore();
const chooseList = ref("");
const payShow = ref(false);
function createClick(val) {
  chooseList.value = val;
  const user = store.getters.user;
  if (!user) {
    return store.dispatch("user/changeLoginTab", true);
  }
  payShow.value = true;
}
const show = ref(true);

async function testClick(val) {
  if (!val.preview_room_url) {
    return Toast("当前空间暂不支持预览, 请联系客服!");
  }
  const user = store.getters.user;
  if (!user) {
    return store.dispatch("user/changeLoginTab", true);
  }
  const { result } = await getRoomAccessToken({ scene_id: val.id });
  if (result.access_token) {
    // const url = `${val.preview_room_url}?access_token=${result.access_token}`;
    const url = `${val.preview_room_url}`;
    location.href = url;
    // jupUrl3(url);
  }
}
</script>
<style lang="scss" scoped>
ul {
  padding: 0 20px;
  flex-wrap: wrap;
  li {
    width: 342px;
    margin-bottom: 40px;
    .picker {
      width: 100%;
      height: 188px;
      position: relative;
      img {
        border-radius: 10px;
      }
      .create_button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);
        text-align: center;
        padding: 8px;
        color: #fff;
      }
    }
    .name {
      font-size: 24px;
      color: #0a0330;
      margin-top: 16px;
    }
  }
}
</style>
