<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:14:54
 * @Description: banner
-->
<template>
  <div class="top_banner offsetTop">
    <ComHeader @navJump="navJump" :p_current="navCurrent" />
    <!-- <div class="muiBox-home">
      <video class="bgVideo" disablepictureinpicture disableremoteplayback playsinline controlslist="nodownload" webkit-playsinline muted loop autoplay preload>
        <source :src="videoPuted" />
      </video>
    </div> -->
    <div class="top_back_png">
      <img src="@/assets/images/topbanner.png" alt="" />
    </div>
    <div class="fixed_bgcolor">
      <div class="web_container">
        <!-- <div class="website_title">
          <img src="@/assets/images/logo-white.svg" alt="" />
          <span>XVR元宇宙</span>
        </div> -->
        <div class="slogn">
          <p class="slogn_cn">数字地名元宇宙</p>
          <p class="slogn_en">地方民俗及特色产业再包装树立海南旅游消费新导向及内容动能，打造文化旅游休闲一体化内容服务提供商。</p>
        </div>
        <!-- <div @click="bannerChangeValue" class="connect_service">创建空间</div> -->
      </div>
      <div class="d_banner_box">
        <swiper
          :slides-per-view="3"
          :loop="true"
          :space-between="20"
          :coverflow-effect="{
            rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
            stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
            depth: 300, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier: 1, //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
            slideShadows: true, //开启slide阴影。默认 true
          }"
          :centeredSlides="true"
          :autoplay="{
            delay: 2000,
            disableOnInteraction: false,
          }"
          :modules="[EffectCoverflow, Autoplay]"
          effect="coverflow"
        >
          <swiper-slide v-for="(item, index) in list" :key="index">
            <div @click="selectBanner(index)" class="img_box">
              <img :src="item.screenshot" alt="" />
              <div class="hover_box">
                <div class="name">{{ item.name }}</div>
                <div class="dec">{{ item.description }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-if="scroll >= docSceenHeight[0]" class="anchorBox">
      <ul>
        <li @click="selectBanner(index)" :class="[index == currentNav ? 'active' : '']" v-for="(item, index) in list" :key="index">{{ item.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, onMounted } from "vue";
import ComHeader from "@/components/ComHeader.vue";
import { useRouter } from "vue-router";
import { jupUrl } from "@/utils/utils";

import { EffectCoverflow, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";

import { spaceCategory } from "@/api/home.service";
export default {
  name: "BannerVideo",
  props: {
    docSceenHeight: {
      type: Array,
      requrie: false,
      default: () => {
        return [];
      },
    },
  },
  components: {
    ComHeader,
    Swiper,
    SwiperSlide,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      navCurrent: 0,
      list: [],
      scroll: 0,
      currentNav: 0,
    });
    const videoPuted = computed(() => {
      return "https://xvr.oss-cn-hangzhou.aliyuncs.com/video/banner.mp4";
    });

    const categoryFn = async () => {
      try {
        let {
          result: { items },
        } = await spaceCategory();
        if (items.length) {
          state.list.push(...items);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const navJump = (index) => {
      emit("navJump", index);
    };

    const bannerChangeValue = () => {
      jupUrl("Spaces");
    };

    const handleScroll = () => {
      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      state.scroll = scroll;

      const n =
        props.docSceenHeight.filter((e) => {
          return e > scroll;
        }).length + 1;
      state.currentNav = props.docSceenHeight.length - n;
    };

    const selectBanner = (index) => {
      // console.log(index);
      emit("selectedTab", index);
    };

    onMounted(() => {
      categoryFn();
      window.addEventListener("scroll", handleScroll);
    });

    return {
      ...toRefs(state),
      navJump,
      videoPuted,
      bannerChangeValue,
      EffectCoverflow,
      Autoplay,
      selectBanner,
    };
  },
};
</script>
<style lang="scss" scoped>
.top_banner {
  position: relative;
  height: 56vh;
  margin: 0 auto;
  .muiBox-home {
    position: absolute;
    // z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: none;
    background: #000;
    :deep(.bgVideo) {
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: none;
      object-fit: cover;
    }
  }
  .top_back_png {
    width: 100%;
    height: 100%;
  }
  .fixed_bgcolor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: rgba(114, 0, 125, 0.63);
    padding-top: 18vh;
    .web_container {
      .website_title {
        text-align: center;
        img {
          width: 412px;
          height: 104px;
          margin: 48px auto 0;
        }
        span {
          font-size: 72px;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .slogn {
        text-align: center;
        margin-top: 30px;
        &_cn {
          font-size: 40px;
          color: #ffffff;
          padding: 0 36px;
          line-height: 60px;
        }
        &_en {
          font-size: 28px;
          color: #ffffff;
          margin-top: 30px;
        }
      }
      .connect_service {
        margin: 40px auto 0;
        width: 436px;
        line-height: 108px;
        background: linear-gradient(90deg, #15d1ea 0%, #0c1ed4 100%);
        border-radius: 30px;
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .d_banner_box {
      width: 100%;
      height: 137px;
      margin: 80px auto 0;
      .swiper-slide {
        width: 600px !important;
        height: auto;
        .img_box {
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          .hover_box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
            padding: 80px 10px 0;
            .name {
              font-size: 42px;
              color: #fff;
            }
            .dec {
              font-size: 28px;
              color: #fff;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .anchorBox {
    position: fixed;
    left: 0;
    top: 144px;
    width: 100%;
    height: 100px;
    z-index: 10;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      li {
        line-height: 72px;
        color: rgb(34, 57, 78);
        font-size: 14px;
        opacity: 0.7;
        cursor: pointer;
      }
      .active {
        opacity: 1;
        color: #22394e;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 3px solid #22394e;
      }
    }
  }
}
</style>
