import axios from "axios";
import configList from "./config";
import qs from "qs";
import store from "@/store";
import { jupUrl } from "@/utils/utils";
import { Toast } from "vant";
export default function $axios(data) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: configList.baseUrl,
      withCredentials: configList.withCredentials,
    });
    instance.interceptors.request.use((config) => {
      const user = store.getters.user;
      if (user && user.access_token) {
        config.headers["Access-Token"] = user.access_token;
      }
      return config;
    });
    instance.interceptors.response.use(
      (response) => {
        // 2xx
        return response;
      },
      function (error) {
        const res = error.response;
        // const user = store.getters.user
        if (res && res.status == 401) {
          store.dispatch("user/deleteUser");
          jupUrl("Home");
          Toast("您尚未登录");
        } else {
          let msg = res.data.msg;
          msg = msg.replace(/\([\d+)]*\)/g, "");
          Toast(msg);
        }
        return Promise.reject(res);
      }
    );
    data.data = qs.stringify({ ...data.data });
    instance(data)
      .then((res) => {
        resolve(res.data);
        return false;
      })
      .catch((error) => {
        reject(error);
      });
  });
}
