<template>
  <van-popup v-model:show="show" closeable position="bottom" @closed="closed">
    <div class="login_box">
      <div v-if="type === 'password'">
        <div class="title">账号密码登录</div>
        <van-field v-model="passwordData.phone" style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px" placeholder="请输入账号" />
        <van-field
          v-model="passwordData.password"
          type="password"
          style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px"
          placeholder="请输入密码"
        />
        <div class="type_button">
          <span style="margin-right: 8px" @click="handleChange('code')">手机号登录</span>
          | <span style="margin-left: 8px" @click="type = 'register'">注册</span>
          <span class="right_button">忘记密码?</span>
        </div>
        <van-button style="width: 100%; margin-top: 20px" @click="submit" class="login_button" type="primary">登录</van-button>
      </div>

      <div v-if="type === 'code'">
        <div class="title">验证码登录</div>

        <van-field
          v-model="codeData.phone"
          :maxlength="11"
          type="tel"
          style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px"
          placeholder="请输入手机号"
        />
        <van-field v-model="codeData.code" :maxlength="6" style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px" placeholder="请输入验证码">
          <template #button>
            <div class="get_code_button" style="color: #999" v-if="codeTimes">{{ `重新获取验证码(${codeTimes}秒)` }}</div>
            <div class="get_code_button link" v-else @click="handleGetCode">获取验证码</div>
          </template>
        </van-field>
        <div class="type_button">
          <span style="margin-right: 8px" @click="handleChange('password')">密码登录</span>
          |
          <span style="margin-left: 8px" @click="type = 'register'">注册</span>
        </div>
        <van-button style="width: 100%; margin-top: 20px" @click="submit" class="login_button" type="primary">登录</van-button>
      </div>
      <!-- <div v-if="type === 'qrcode'">
        <div class="qrcode_box">
          <div class="img_box">
            <img v-if="qrcode" :src="qrcode" :class="checkTimes === 0 ? 'blur' : ''" />
            <qrcode-vue v-if="qrcodeList.url" class="code_main" :value="qrcodeList.url" :size="qrcodeList.size" level="H" />
          </div>
          <div class="title_item">
            <i class="colorfont color-weixin" /> 微信扫码
          </div>
          <div class="mask" v-if="checkTimes === 0">
            <div class="cover_middl">
              <div>二维码已失效</div>
              <div class="refresh" @click="getQrcode"><i class="iconfont icon_refresh icon-shuaxin"></i> 点击刷新</div>
            </div>
          </div>
        </div>
        <div class="type_button"><span style="margin-right:8px;" @click="handleChange('password')">短信密码登录</span> | <span style="margin-left:8px;" @click="handleChange('code')">手机号登录</span></div>
      </div> -->

      <div v-if="type === 'register'">
        <div class="input_box">
          <van-field
            v-model="registerData.phone"
            :maxlength="11"
            type="tel"
            style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px"
            placeholder="请输入手机号"
          />
        </div>
        <div class="input_box">
          <van-field
            class="input_item"
            type="password"
            style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px"
            v-model="registerData.password"
            placeholder="请输入登录密码"
          />
        </div>
        <div class="input_box">
          <van-field
            v-model="registerData.code"
            :maxlength="6"
            style="border: 1px solid #e9e7e7; border-radius: 5px; margin-top: 20px"
            placeholder="请输入验证码"
          >
            <template #button>
              <div class="get_code_button c9" v-if="registerTimes">{{ `重新获取验证码(${registerTimes}秒)` }}</div>
              <div class="get_code_button link" v-else @click="registerGetCode">获取验证码</div>
            </template>
          </van-field>
        </div>
        <div class="forget_item link"></div>
        <div class="login_item">
          <van-button style="width: 100%; margin-top: 20px" @click="handleRegister" class="login_button" type="primary">注册</van-button>
        </div>
        <div class="type_button" style="text-align: center">
          <span style="margin-right: 8px; color: #999">已有账号？</span><span @click="handleChange('code')">去登录</span>
        </div>
      </div>

      <div class="privacy_main">
        <Privacy title="登录视为同意" />
      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted } from "vue";
import { Privacy } from "@/components/Popup";
import { useStore } from "vuex";
import { createUnionSessionWithQrScene, checkScanResult, smsSend, userRegister } from "@/api/w-axios/modules/login.js";
import { isMobile } from "@/utils/validate.js";
import { Toast } from "vant";
import QrcodeVue from "qrcode.vue";
const show = ref(false);
const store = useStore();
const type = ref("code");
onMounted(() => {
  show.value = true;
  // getQrcode();
});
function closed() {
  store.dispatch("user/changeLoginTab", false);
}
const passwordData = reactive({
  phone: "",
  password: "",
});
async function submit() {
  const val = type.value === "password" ? passwordData : type.value === "code" ? codeData : "";
  await store.dispatch("user/login", val);
  show.value = false;
}
function handleChange(val) {
  clearTimeout(startTimer);
  startTimer = null;
  clearInterval(timer);
  timer = null;
  checkTimes.value = 60;
  type.value = val;
}
function weChatClick() {
  type.value = "qrcode";
  getQrcode();
}

//验证码登录
const codeData = reactive({
  phone: "",
  code: "",
});

const loading = ref(false);
async function handleGetCode() {
  if (!isMobile(codeData.phone)) {
    return Toast("请输入正确的手机号码");
  }
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    const res = await smsSend({
      phone: codeData.phone,
    });
    if (res.code === 200) {
      tickTick();
    }
  } catch (err) {
    loading.value = false;
  }
}

const codeTimes = ref(0);
function tickTick() {
  codeTimes.value = 60;
  timer = setInterval(() => {
    if (codeTimes.value > 0) {
      codeTimes.value--;
    } else {
      loading.value = false;
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
}

//二维码扫码登录
const qrcode = ref("");
const sessionData = reactive({
  session_id: "",
  scope: "",
});
const qrcodeList = reactive({
  url: "",
  size: 162,
});
async function getQrcode() {
  loading.value = true;
  // const cookie = getCookie();
  let scope = process.env.VUE_APP_TITLE;
  // sessionData.session_id = cookie?.SID // 未启用session的后端会自动生成并返回

  try {
    const { result } = await createUnionSessionWithQrScene({
      type: "portal",
      scope,
    });
    qrcode.value = result.url;
    sessionData.scope = result.scope;
    sessionData.session_id = result.session_id;
    setTimeout(() => {
      loading.value = false;
    }, 650);
  } catch (err) {
    loading.value = false;
    checkTimes.value = 0;
    // scope = encodeURIComponent(scope)
    // const time = new Date().getTime()
    // const url = `${process.env.VUE_APP_MP_BASE_URL}?type=portal&scope=${scope}&session_id=1&_t=${time}`
    // console.log(url)
    // qrcodeList.url = url
  }
  reset();
}
async function generateQRCode(text) {
  try {
    qrcode.value = await QRCode.toDataURL(text, {
      width: 300,
      color: {
        dark: "#224A95FF",
        light: "#EFEFEFFF",
      },
    });
    reset();
  } catch (err) {
    Toast("生成二维码失败");
    console.error(err);
  }
}
const checkTimes = ref(60);
let timer = null;
async function reset() {
  checkTimes.value = 60;
  await handleStart();
  timerFuc();
}

let startTimer = null;
async function handleStart() {
  const user = await handleScanResult();
  if (user) {
    store.dispatch("user/setUser", user);
    message.success("登录成功");
    store.dispatch("user/changeLoginTab", false);
    if (route.name !== "Scene") {
      jupUrl("Scene");
    }
    return;
  }
  clearTimeout(startTimer);
  startTimer = null;
  if (type.value !== "qrcode") {
    return;
  }
  startTimer = setTimeout(() => {
    if (checkTimes.value > 0) {
      handleStart();
    }
  }, 1000);
}
function timerFuc() {
  timer = setInterval(() => {
    if (checkTimes.value > 0) {
      checkTimes.value--;
    } else {
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
}
async function handleScanResult() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!sessionData.session_id) {
        return resolve(false);
      }
      const { result } = await checkScanResult(sessionData);
      if (result.continue === 1) {
        return resolve(false);
      }
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
}
//账号注册
const registerData = reactive({
  phone: "",
  password: "",
  email: "",
  code: "",
});
async function registerGetCode() {
  if (!isMobile(registerData.phone)) {
    return Toast("请输入正确的手机号码");
  }
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    const res = await smsSend({
      phone: registerData.phone,
    });
    if (res.code === 200) {
      registerTickTick();
    }
  } catch (err) {
    console.log(err);
    Toast(err.data.msg);
    loading.value = false;
  }
}

const registerTimes = ref(0);
function registerTickTick() {
  registerTimes.value = 60;
  timer = setInterval(() => {
    if (registerTimes.value > 0) {
      registerTimes.value--;
    } else {
      loading.value = false;
      clearInterval(timer);
      timer = null;
    }
  }, 1000);
}
async function handleRegister() {
  try {
    const res = await userRegister(registerData);
    if (res.code === 200) {
      Toast("注册成功，请重新登录");
      type.value = "password";
    }
  } catch (error) {
    console.log(error);
  }
}

onUnmounted(() => {
  clearInterval(timer);
  clearTimeout(startTimer);
});
</script>
<style lang="scss" scoped>
.login_box {
  text-align: center;
  padding-top: 60px;
  min-height: 555px;
  padding: 40px;
  .title {
    font-size: 36px;
  }
  .type_button {
    margin-top: 40px;
    text-align: left;
    color: #19a0f1;
  }
  .right_button {
    float: right;
    color: #999;
  }
  .login_button {
    background: linear-gradient(91deg, #50daff 0%, #1d2eda 100%);
    border: none;
  }
  .privacy_main {
    margin-top: 40px;
  }
}
.qrcode_box {
  overflow: hidden;
  padding: 12px;
  border: 1px solid #efefef;
  display: inline-block;
  box-shadow: 0px 15px 30px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: table;

    .cover_middl {
      display: table-cell;
      vertical-align: middle;
      color: #fff;

      .refresh {
        display: inline-block;
        line-height: 28px;
        padding: 0 12px;
        background-color: #fff;
        border-radius: 4px;
        font-size: 12px;
        color: #333;
        margin-top: 10px;
        cursor: pointer;

        .icon_refresh {
          font-size: 12px;
        }
      }
    }
  }

  .img_box {
    width: 300px;
    height: 300px;
    overflow: hidden;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    img.blur {
      filter: blur(3px);
    }
  }

  .title_item {
    margin-top: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #9e9e9e;

    .icon_wechat {
      vertical-align: -1px;
    }
  }
}
</style>
