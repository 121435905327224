<template>
  <div class="category_sceen_box web_container">
    <div class="sceen_list" v-for="(item, index) in list" :key="index">
      <div class="cate_box">
        <div class="max_categoy_name">{{ item.name }}</div>
        <div class="dec">{{ item.description }}</div>
        <img src="@/assets/images/xjt.png" />
      </div>
      <ul>
        <li v-for="(childItem, childIndex) in item.scenes" :key="childIndex">
          <div class="pos_box">
            <div class="picker">
              <img :src="childItem.screenshot" alt="" />
              <div class="type_box">{{ item.name }}</div>
            </div>
            <div class="name">{{ childItem.name }}</div>
            <div class="use_det">
              <div class="use_value">浏览量：{{ childItem.viewnum }}</div>
              <span> | </span>
              <div class="use_value">使用量：{{ childItem.usenum }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { reactive, toRefs, onMounted, nextTick } from "vue";
import { listByCategory } from "@/api/home.service";
import { jupUrl3 } from "@/utils/utils.js";
import { useStore } from "vuex";
export default {
  name: "CategotySceenBox",
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      list: [],
    });

    const spaceListFn = async () => {
      try {
        let {
          result: { items },
        } = await listByCategory({
          limit: 8,
        });
        if (items.length) {
          state.list.push(...items);
          getDocList();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const jump = (item) => {
      if (!item.preview_room_url) {
        return message.info("当前空间暂不支持预览, 请联系客服!");
      }
      const user = store.getters.user;
      if (!user) {
        return store.dispatch("user/changeLoginTab", true);
      }
      jupUrl3(item.preview_room_url);
    };

    const getDocList = () => {
      nextTick(() => {
        const doc = document.querySelectorAll(".sceen_list");
        let values = [];
        Array.from(doc).forEach((e) => {
          let value = e.offsetTop - 160;
          // console.log(value);
          values.push(value);
        });
        emit("getSceenHeight", values);
      });
    };

    onMounted(() => {
      spaceListFn();
    });

    return {
      ...toRefs(state),
      jump,
    };
  },
};
</script>
<style lang="scss" scoped>
.category_sceen_box {
  width: 100%;
  text-align: center;
  .cate_box {
    .max_categoy_name {
      font-size: 32px;
      color: #000000;
    }
    .dec {
      font-size: 18px;
      color: #666;
      margin-top: 10px;
    }
    img {
      width: 32px;
      height: 32px;
      margin: 10px auto 0;
    }
  }
}
ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 30px 0 30px;
  li {
    width: 335px;
    margin-bottom: 60px;
    cursor: pointer;
    transition: all 0.3s;
    .pos_box {
      .picker {
        position: relative;
        width: 100%;
        height: 210px;
        border-radius: 10px;
        overflow: hidden;
        .type_box {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          color: #fff;
          text-align: left;
          padding: 10px 0 0 10px;
        }
      }
      .name {
        width: 100%;
        line-height: 50px;
        font-size: 14px;
        color: #0a0330;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
      .use_det {
        display: flex;
        color: #666;
        font-size: 14px;
        text-align: left;
        span {
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
