import Moment from "moment";
import router from "@/router";
Moment.locale("zh-CN");

export const time2date = (time) => {
  return Moment(time).format("YYYY-MM-DD");
};

export const time2datetime = (time) => {
  return Moment(time).format("YYYY-MM-DD HH:mm:ss");
};
export const myDatetime = (time, format) => {
  return Moment(time).format(format);
};

const today = Moment();
const yesterday = Moment().subtract(1, "days");
const monday = Moment().week(Moment().week()).startOf("week");
const sunday = Moment().week(Moment().week()).endOf("week");
const lastMonday = Moment()
  .week(Moment().week() - 1)
  .startOf("week");
const lastSunday = Moment()
  .week(Moment().week() - 1)
  .endOf("week");
const beginOfThisMonth = Moment().startOf("month");
const beginOfLastMonth = Moment()
  .month(Moment().month() - 1)
  .startOf("month");
const endOfLastMonth = Moment()
  .month(Moment().month() - 1)
  .endOf("month");
export const dateRanges = {
  今天: [today, today],
  昨天: [yesterday, yesterday],
  本周: [monday, sunday],
  上周: [lastMonday, lastSunday],
  本月: [beginOfThisMonth, today],
  上月: [beginOfLastMonth, endOfLastMonth],
};

export const formatNumber = (value) => {
  if (!value) {
    return "0.00";
  }
  return new Number(value / 100).toFixed(2);
};

export const fuzzMobile = (phonenumber) => {
  if (!phonenumber) {
    return "-";
  }
  const stringNumber = String(phonenumber);
  if (stringNumber.length == 11) {
    return stringNumber.replace(/^(\d{3})\d{6}(\d{2})$/, "$1******$2");
  }
  return stringNumber;
};

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
}

export const wySlider = (fn, options = {}) => {
  return new Promise((resolve) => {
    let { element, mode, width } = options;
    let captchaIns = {
      onLoad: false,
      validateResult: false,
      instance: null,
    };

    initNECaptcha(
      {
        captchaId: "5a002bbfb9e44c4188b6a90c532066c0",
        element: element || "#captcha",
        mode: mode || "popup",
        width: width || "320px",
        onVerify: (err, data) => {
          if (data) {
            captchaIns.validateResult = true;
            fn && fn();
          }
        },
      },
      function onload(instance) {
        captchaIns.onLoad = true;
        captchaIns.instance = instance;
        resolve(captchaIns);
      },
      function onerror(err) {
        console.log(err);
        resolve(captchaIns);
      }
    );
  });
};

export function jupUrl(name, params, query) {
  router.push({ name, params, query });
}
export function wait(val) {
  return new Promise(async function (resolve, reject) {
    setTimeout(() => {
      resolve();
    }, val);
  });
}

export function jupUrl3(url) {
  if (!url) {
    return;
  }
  const newUrl = getUrl(url);
  console.log(newUrl);
  if (newUrl === location.hostname) {
    router.push({ path: url.substr(url.indexOf("/", 8)) });
  } else {
    window.open(url, "_blank");
  }
}

export function getUrl(url) {
  var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
  var newUrl = urlReg.exec(url);
  return newUrl[0];
}

export function getQuery(name) {
  // 正则：[找寻'&' + 'url参数名字' = '值' + '&']（'&'可以不存在）
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    // 对参数值进行解码
    return decodeURIComponent(r[2]);
  }
  return null;
}

export function getUrlQuery(url, name) {
  // 正则：[找寻'&' + 'url参数名字' = '值' + '&']（'&'可以不存在）
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = url.substr(1).match(reg);
  if (r != null) {
    // 对参数值进行解码
    return decodeURIComponent(r[2]);
  }
  return null;
}
