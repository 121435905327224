<!--
 * @Author: 大年
 * @Date: 2022-09-05 14:22:52
 * @Description: XVR手机端
-->
<template>
  <div class="xvr_home">
    <!-- banner -->
    <BannerVideo @selectedTab="selectedTab" :docSceenHeight="docSceenHeight" />

    <CategorySceen @getSceenHeight="getSceenHeight" />

    <!-- 空间展示 -->
    <!-- <SpaceDisplay @loadSucc="getOffsetTop" /> -->

    <!-- 联系我们 -->
    <!-- <div class="wow slideInUp">
      <Contact />
    </div> -->

    <!-- 合作伙伴 -->
    <!-- <Partners /> -->

    <ComFooter />

    <MenuBox />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, nextTick } from "vue";
import ComFooter from "@/components/ComFooter.vue";
import MenuBox from "@/components/MenuBox.vue";
import { initWow } from "@/utils/wow";
import Partners from "./components/Partners.vue";
import Contact from "./components/Contact.vue";
import BannerVideo from "./components/BannerVideo.vue";
import SpaceDisplay from "./components/SpaceDisplay.vue";
import CategorySceen from "./components/CategorySceen.vue";

export default {
  name: "XvrHome",
  components: {
    ComFooter,
    MenuBox,
    Partners,
    Contact,
    BannerVideo,
    SpaceDisplay,
    CategorySceen,
  },
  setup() {
    const state = reactive({
      moduleHeight: [],
      docSceenHeight: [],
    });

    onMounted(() => {
      initWow();
    });

    const navJump = (index) => {
      let currentScrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      let jump = state.moduleHeight[index];
      let cv = jump - currentScrollTop;

      let o = cv / 30;
      let value = currentScrollTop;
      let timer = setInterval(() => {
        value += o;
        if (cv > 0) {
          if (value >= jump) {
            value = jump;
            clearInterval(timer);
          }
        } else {
          if (value <= jump) {
            value = jump;
            clearInterval(timer);
          }
        }
        document.body.scrollTop = document.documentElement.scrollTop = value;
      }, 10);
    };

    const getOffsetTop = () => {
      nextTick(() => {
        const headerHeightAuto = document.querySelector(".com_header").clientHeight;
        // console.log(headerHeightAuto);
        Array.from(document.querySelectorAll(".offsetTop")).forEach((e, i) => {
          let value = e.offsetTop;
          if (i > 0) {
            value -= headerHeightAuto; //减去顶部导航栏高度
          }
          state.moduleHeight.push(value);
        });
      });
    };

    const getSceenHeight = (values) => {
      state.docSceenHeight.push(...values);
    };

    const selectedTab = (index) => {
      document.body.scrollTop = document.documentElement.scrollTop = state.docSceenHeight[index];
    };

    return {
      ...toRefs(state),
      navJump,
      getOffsetTop,
      getSceenHeight,
      selectedTab,
    };
  },
};
</script>
<style lang="scss" scoped>
.xvr_home {
  padding-bottom: 150px;
}
</style>
