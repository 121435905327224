/*
 * @Author: 大年
 * @Date: 2022-08-04 17:34:03
 * @Description: vant组件加载
 */
import { Swipe, SwipeItem, Field, Popup, Button, Icon, Cell, Tabs, Tab, Tag, Dialog, List } from "vant";
import "vant/lib/index.css";
const loadVant = (app) => {
  app.use(Swipe).use(SwipeItem).use(Popup).use(Field).use(Button).use(Icon).use(Cell).use(Tabs).use(Tab).use(Dialog).use(List).use(Tag);
};
export { loadVant };
